// src/components/PriceChart.tsx
import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

interface PriceChartProps {
  priceHistory: { date: string; price: number }[];
}

const PriceChart: React.FC<PriceChartProps> = ({ priceHistory }) => {
  const chartData = {
    labels: priceHistory.map(entry => entry.date),
    datasets: [
      {
        label: 'GEN Price (USD)',
        data: priceHistory.map(entry => entry.price),
        borderColor: '#ffd700',
        backgroundColor: 'rgba(255, 215, 0, 0.1)',
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 3,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide legend if not necessary
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Optional: Remove x-axis grid lines
        },
        ticks: {
          maxTicksLimit: 6, // Limit the number of x-axis labels
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: '#d1d5db', // Light gray color for grid lines
        },
      },
    },
  };

  // Get the latest price from the price history
  const latestPrice = priceHistory.length > 0 ? priceHistory[priceHistory.length - 1].price : null;

  return (
    <div style={{ position: 'relative', height: '202px', width: '100%' }} >
      {/* <div style={{ padding: '10px 0', fontWeight: 'bold' }}>
        {latestPrice !== null ? `1 GEN : $${latestPrice.toFixed(2)}` : 'Price data unavailable'}
      </div> */}
      <Line data={chartData} options={chartOptions} />
    </div>
  );
};

export default PriceChart;
