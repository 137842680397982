import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { gencoin } from '../images';
import { NavLink } from 'react-router-dom';
import { ethers } from 'ethers';

type CoinInfo = {
  name: string;
  symbol: string;
  priceUsd: number;
  priceUsd24hAgo: number;
  [key: string]: any; // Add other properties if your API returns more fields
};

const Homepage: React.FC = () => {

  const EXPLOITATION_FEES = 300;
  const CHAINLINK_PRICE_FEED_ADDRESS = '0x86896fEB19D8A607c3b11f2aF50A0f239Bd71CD0';

  const aggregatorV3InterfaceABI = [
    {
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "description",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
      name: "getRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "latestRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "version",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
  ];

  const [coinData, setCoinData] = useState<CoinInfo[]>([]);
  const [goldPrice, setGoldPrice] = useState<number | null>(null);
  const [genPrice, setGenPrice] = useState<number | null>(null);
  const [chartPriceHistory, setChartPriceHistory] = useState<{ date: string; price: number }[]>([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };


  useEffect(() => {
    const fetchCoinData = async () => {
      const url = "https://api.coinbrain.com/public/coin-info";
      const payload = {
        "56": ["0x0042e6b7db042cf384c60be5fa347de8a10dd9b3"], // Replace with actual token addresses
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const data = await response.json();
          setCoinData(data);
        } else {
          console.error("Failed to fetch coin data.");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    fetchCoinData();
  }, []);

  useEffect(() => {
    const fetchGoldPrice = async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org/");
        const priceFeed = new ethers.Contract(CHAINLINK_PRICE_FEED_ADDRESS, aggregatorV3InterfaceABI, provider);
        const latestRoundData = await priceFeed.latestRoundData();
        const decimals = await priceFeed.decimals();
        const price = Number(latestRoundData.answer) / 10 ** decimals;
        setGoldPrice(price);
      } catch (error) {
        console.error('Error fetching gold price:', error);
      }
    };

    fetchGoldPrice();
  }, []);
  
  useEffect(() => {
    if (goldPrice !== null) {
      setGenPrice(goldPrice - EXPLOITATION_FEES);
    }
  }, [goldPrice]);
  
  useEffect(() => {
    if (coinData.length > 0) {
      const currentPrice = coinData[0].priceUsd;
      setChartPriceHistory(prevData => [
        ...prevData,
        { date: new Date().toLocaleDateString(), price: currentPrice },
      ]);
    }
  }, [coinData]);

  const formatMoney = (value: number) => {
    return value?.toLocaleString("en-US", { style: "currency", currency: "USD" }) || "N/A";
  };

  const priceChangePercentage = coinData.length && coinData[0].priceUsd24hAgo
    ? ((coinData[0].priceUsd - coinData[0].priceUsd24hAgo) / coinData[0].priceUsd24hAgo * 100).toFixed(2)
    : "0.00";

  const PriceComparison = () => {
    const genPriceFormatted = genPrice ? formatMoney(genPrice) : "$0.00 USD";
    const currentPrice = coinData.length > 0 ? coinData[0].priceUsd : 0;
    const currentPriceFormatted = currentPrice ? formatMoney(currentPrice) : "$0.00 USD";

    return (
      <PriceComparisonContainer>
        <PriceItem>
          <PriceLabel>GEN Price:</PriceLabel>
          <PriceValue>{genPriceFormatted}</PriceValue>
        </PriceItem>
        <PriceItem>
          <PriceLabel>Initial Market Price:</PriceLabel>
          <PriceValue>{currentPriceFormatted}</PriceValue>
        </PriceItem>
        <PriceItem>
          <PriceLabel>Price Change (24h):</PriceLabel>
          <PriceValue>{priceChangePercentage}%</PriceValue>
        </PriceItem>
      </PriceComparisonContainer>
    );
  };

 
  return (
    <MainContainer>
      <Header>
        <Logo src={gencoin} alt="Gold Era NFT Logo" />
        <Nav isMobileMenuOpen={isMobileMenuOpen} aria-label="Main Navigation">
        <NavLink to="/dashboard"><NavItem  >Token Details</NavItem></NavLink>
        <NavLink to="/documents"><NavItem >Whitepaper</NavItem></NavLink>
        <NavItem href="/coin-info">API</NavItem>
          <CreateAccountButton><NavLink to="/dashboard" target="_blank">Explore Now</NavLink></CreateAccountButton>
        </Nav>
        <MobileMenuButton onClick={toggleMobileMenu} aria-label="Toggle Menu">
          ☰
        </MobileMenuButton>
      </Header>
      <HeroSection>
        <HeroContent>
        <center><CoinLogo src={gencoin} alt="Gold Era NFT Logo" /></center>
          <HeroTitle>Gold Era NFT</HeroTitle>
          <HeroSubtitle>
            A Real-World Asset-Backed Token. Each GEN token is backed by one troy ounce of physical, allocated in-ground gold.
          </HeroSubtitle>
          <PriceComparison />

          <ButtonContainer className="mt-4">
            <ActionButton><NavLink to="/dashboard" target="_blank">Explore Now</NavLink></ActionButton>
          </ButtonContainer>
        </HeroContent>
      </HeroSection>
      {/* <ContentSection>
        <AboutText>
          The Gold Era NFT (GEN) is a real-world asset-backed token, with each GEN token representing one troy ounce of physical, allocated in-ground gold. 
        </AboutText>
      </ContentSection>
       */}
      <Footer>
        <FooterContent>
          <FooterLinks>
            <FooterLink href="#">Privacy Policy</FooterLink>
            <FooterLink href="#">Terms of Service</FooterLink>
          </FooterLinks>
          <FooterText>&copy; {new Date().getFullYear()} Gold Era NFT. All rights reserved.</FooterText>
        </FooterContent>
      </Footer>
    </MainContainer>
  );
};

// Define types for props
interface NavProps {
  isMobileMenuOpen: boolean;
}

const MainContainer = styled.div`
  background: #0b0b0b;
  color: #fff;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Logo = styled.img`
  width: 84px;
  height: 84px;
`;

const CoinLogo = styled.img`
  width: 150px;
  height: 150px;
`;

const Nav = styled.nav<NavProps>`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: 768px) {
    display: ${props => (props.isMobileMenuOpen ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    top: 82px;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    width: 100%;
    text-align: right;
    padding: 1rem;
  }
`;

const NavItem = styled.a`
  color: #d4af37;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;

  &:hover,
  &:focus {
    color: #fff;
    border-bottom: 2px solid #d4af37;
  }

  @media (max-width: 768px) {
    padding: 1rem 0;
  }
`;

const CreateAccountButton = styled.button`
  background: #d4af37;
  color: #000;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;

  &:hover,
  &:focus {
    background: #b89630;
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const MobileMenuButton = styled.div`
  display: none;
  font-size: 2.5rem;
  color: #d4af37;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const HeroSection = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(270deg, #1a1a1a, #2c2c2c, #0b0b0b, #2c2c2c);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite;
  color: #d4af37;
  text-align: center;
  padding: 0 1rem;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const HeroContent = styled.div`
  max-width: 800px;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 1.25rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
`;

const ActionButton = styled.button`
  background: #d4af37;
  color: #000;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s;

  &:hover,
  &:focus {
    background: #b89630;
  }
`;

const ContentSection = styled.section`
  padding: 4rem 2rem;
  background: #121212;
  color: #fff;
  width: 100%;
  text-align: center;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: #d4af37;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const AboutText = styled.p`
  font-size: 1.125rem;
  line-height: 1.75;
  margin: 0 auto;
  max-width: 800px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PartnersSection = styled.section`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background: #0b0b0b;
  width: 100%;

  h2 {
    font-size: 2rem;
    color: #d4af37;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;

const PartnersLogos = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
`;

const PartnerLogo = styled.img`
  max-width: 100px;
  opacity: 0.8;
  transition: opacity 0.3s;

  &:hover,
  &:focus {
    opacity: 1;
  }

  @media (max-width: 768px) {
    max-width: 80px;
  }
`;

const Footer = styled.footer`
  padding: 2rem 0;
  background: #121212;
  color: #fff;
  width: 100%;
  text-align: center;
  border-top: 1px solid #444;
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const FooterLink = styled.a`
  color: #d4af37;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const FooterText = styled.p`
  font-size: 0.875rem;
`;

const PriceComparisonContainer = styled.div`
  margin-top: 2rem;
`;

const PriceItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  margin: 0.5rem 0;
`;

const PriceLabel = styled.span`
  font-weight: 600;
`;

const PriceValue = styled.span`
  color: #d4af37;
`;

export default Homepage;
