// src/components/Card.tsx
import React from 'react';

interface CardProps {
  title: string;
  value: string;
}

const Card: React.FC<CardProps> = ({ title, value }) => {
  return (
    <div className="card bg-gray-800 p-4 rounded-lg shadow-md hover:shadow-lg transform hover:scale-105 transition-transform">
      <h2 className="text-lg font-semibold text-gray-200 mb-2">{title}</h2>
      <p className="text-xl font-bold text-gray-100">{value}</p>
    </div>
  );
};

export default Card;
