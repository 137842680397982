import React from "react";
import styled from "styled-components";

interface DocumentCardProps {
  title: string;
  coverImage: string;
  description: string;
  onView: () => void;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ title, coverImage, description, onView }) => {
  return (
    <Card onClick={onView}>
      <CoverImage src={coverImage} alt={`${title} cover`} />
      <CardContent>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ViewLink onClick={onView}>
          View Document
        </ViewLink>
      </CardContent>
    </Card>
  );
};

const Card = styled.div`
  width: 300px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
  }

  &:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  }
`;

const CoverImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 150px;
  }
`;

const CardContent = styled.div`
  padding: 1rem;
`;

const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #4b5563;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const ViewLink = styled.a`
  display: inline-block;
  margin-top: 1rem;
  background: #1f2937;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    background: #4b5563;
  }
`;

export default DocumentCard;
