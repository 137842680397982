import React from "react";
import styled from "styled-components";

interface ModuleCardProps {
  module: {
    title: string;
    description: string;
    image: string;
    link: string;
  };
}

const ModuleCard: React.FC<ModuleCardProps> = ({ module }) => {
  return (
    <Card>
      <Image src={module.image} alt={`${module.title} image`} />
      <CardContent>
        <Title>{module.title}</Title>
        <Description>{module.description}</Description>
      </CardContent>
      <LearnMore href={module.link} target="_blank">
        Coming Soon
      </LearnMore>
    </Card>
  );
};

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const CardContent = styled.div`
  flex: 1;
  padding: 1rem;
`;

const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #4b5563;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const LearnMore = styled.a`
  display: inline-block;
  background: #1f2937;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  align-self: flex-end;
  margin: 1rem;

  &:hover {
    background: #4b5563;
  }
`;

export default ModuleCard;
