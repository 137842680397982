import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "@chakra-ui/react";
import Navbar from "../components/NavBar";
import { ethers } from "ethers";
import { gencoin } from "../images";
import { NavLink } from "react-router-dom";

interface CoinInfo {
  address: string;
  chainId: number;
  priceUsd: number;
  priceUsd24hAgo: number;
  marketCapUsd: number;
  totalReserveUsd: number;
}

const DataPage: React.FC = () => {
  const EXPLOITATION_FEES = 300;
  const CHAINLINK_PRICE_FEED_ADDRESS = '0x86896fEB19D8A607c3b11f2aF50A0f239Bd71CD0';

  const aggregatorV3InterfaceABI = [
    {
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "description",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
      name: "getRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "latestRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "version",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
  ];

  const [coinData, setCoinData] = useState<CoinInfo[]>([]);
  const [goldPrice, setGoldPrice] = useState<number | null>(null);
  const [genPrice, setGenPrice] = useState<number | null>(null);

  useEffect(() => {
    async function fetchCoinData() {
      const url = "https://api.coinbrain.com/public/coin-info";
      const payload = {
        "56": ["0x0042e6b7db042cf384c60be5fa347de8a10dd9b3"], // Replace with actual token addresses
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const data = await response.json();
          setCoinData(data);
        } else {
          console.error("Failed to fetch coin data.");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }

    fetchCoinData();
  }, []);

  useEffect(() => {
    async function fetchGoldPrice() {
      try {
        const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org/");
        const priceFeed = new ethers.Contract(CHAINLINK_PRICE_FEED_ADDRESS, aggregatorV3InterfaceABI, provider);
        const latestRoundData = await priceFeed.latestRoundData();
        const decimals = await priceFeed.decimals();
        const price = Number(latestRoundData.answer) / 10 ** decimals;
        setGoldPrice(price);
      } catch (error) {
        console.error('Error fetching gold price:', error);
      }
    }
    fetchGoldPrice();
  }, []);
  
  useEffect(() => {
    if (goldPrice !== null) {
      const genPriceCalculated = goldPrice - EXPLOITATION_FEES;
      setGenPrice(genPriceCalculated);
    }
  }, [goldPrice]);

  const formatMoney = (value: number) => {
    return value?.toLocaleString("en-US", { style: "currency", currency: "USD" }) || "N/A";
  };

  return (
    <DataPageContainer>
      <Navbar />
      <ContentContainer>
        <Card>
          <CardHeader>
            <LogoContainer>
              <img src={gencoin} alt="Gold Era NFT Logo" />
              <div>
                <Title>Gold Era NFT (GEN)</Title>
                <AssetTags>
                  <Tag>Commodity</Tag>
                  <Tag>Gold</Tag>
                  <Tag>RWA</Tag>
                </AssetTags>
              </div>
            </LogoContainer>
          </CardHeader>

          <Table>
            <thead>
              <tr>
                <th>Information</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Token Name</td>
                <td>Gold Era NFT</td>
              </tr>
              <tr>
                <td>Symbol</td>
                <td>GEN</td>
              </tr>
              <tr>
                <td>1 GEN</td>
                <td>backed by 1 Troy Oz</td>
              </tr>
              <tr>
                <td>Gold Price</td>
                <td>{goldPrice ? formatMoney(goldPrice) : "$0.00 USD"}</td>
              </tr>
              <tr>
                <td>1 GEN Price</td>
                <td>{genPrice ? formatMoney(genPrice) : "$0.00 USD"}</td>
              </tr>
              <tr>
                <td>Initial Price</td>
                <td>{coinData.length > 0 ? formatMoney(coinData[0].priceUsd) : "N/A"}</td>
              </tr>
              <tr>
                <td>Chain ID</td>
                <td>{coinData.length > 0 ? coinData[0].chainId : "N/A"}</td>
              </tr>
              <tr>
                <td>Market Cap</td>
                <td>{coinData.length > 0 ? formatMoney(coinData[0].marketCapUsd) : "$0.00 USD"}</td>
              </tr>
              <tr>
                <td>Total Reserve</td>
                <td>{coinData.length > 0 ? formatMoney(coinData[0].totalReserveUsd) : "N/A"}</td>
              </tr>
             
              <tr>
                <td>Price 24h Ago</td>
                <td>{coinData.length > 0 ? formatMoney(coinData[0].priceUsd24hAgo) : "N/A"}</td>
              </tr>
              
              {/* <tr>
                <td>Contract Address</td>
                <td>{coinData.length > 0 ? coinData[0].address : "N/A"}</td>
              </tr> */}
            </tbody>
          </Table>

          <Description>
          The Gold Era NFT (GEN) is a real-world asset-backed token, with each GEN token representing one troy ounce of in-ground gold. Contributors are being offered a token fully supported by real gold in-ground, tangible assets situated in Guinea, West Africa <NavLink to="/documents" className="font-bold text-blue">(See authentication documents)</NavLink>. Gold Era NFT a crypto token that is paired with and backed by mineral resources located within the concessions of Atlantic Gold Mine SARL in northeast Guinea, a region renowned for its abundant deposits of gold and other minerals.
          </Description>
        </Card>
      </ContentContainer>
    </DataPageContainer>
  );
};

const DataPageContainer = styled(Container)`
// //  margin-left: 12vw;
    padding: 2rem;
    margin-top:2rem;
//     color: #1f2937;
//     margin-right: -1vw;

`;

const PageTitle = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  color: #333;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Card = styled.div`
  border-radius: 8px;
  padding: 1rem;
  max-width: 800px;
  width: 100%;
  
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: #b8860b; /* Golden text color */
`;

const AssetTags = styled.div`
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
`;

const Tag = styled.span`
  background: #fff7e6; /* Light gold tag background */
  color: #b8860b; /* Golden text color */
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;

  th, td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }

  th {
    background-color: #f0e68c; /* Light gold */
    color: #1f2937;
    font-size: 1rem;
    font-weight: 600;
  }

  tr:nth-child(even) {
    background-color: #f9fafb;
  }

  tr:hover {
    background-color: #f1f1f1;
  }

  td {
    font-size: 1rem;
    color: #1f2937;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.5;
  margin-top: 1.5rem;
`;

export default DataPage;
