import Sidebar from "../components/sidebar";
import styled from "styled-components";
import { Outlet } from "react-router-dom";

export default function Root() {
  return (
    <Div>
      <Sidebar />
      <MainContent>
        <Outlet />
      </MainContent>
    </Div>
  );
}

const Div = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 2rem;
  background: #f3f4f6;

  @media (max-width: 768px) {
    padding: 1rem;
    margin-top: 50px; // to account for the fixed mobile header
  }
`;
