import React from "react";
import styled from "styled-components";

interface MarketCardProps {
  market: {
    name: string;
    logo: string;
    description: string;
    url: string;
  };
}

const MarketCard: React.FC<MarketCardProps> = ({ market }) => {
  return (
    <Card>
      <Logo src={market.logo} alt={`${market.name} logo`} />
      <CardContent>
        <Title>{market.name}</Title>
        <Description>{market.description}</Description>
        <VisitLink href={market.url} target="_blank">
          Visit {market.name}
        </VisitLink>
      </CardContent>
    </Card>
  );
};

const Card = styled.div`
  width: 300px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Logo = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding: 1rem;
`;

const Title = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #4b5563;
  margin-top: 0.5rem;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const VisitLink = styled.a`
  display: inline-block;
  margin-top: 1rem;
  background: #1f2937;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    background: #4b5563;
  }
`;

export default MarketCard;
