import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "@chakra-ui/react";
import Navbar from "../components/NavBar";
import PriceChart from "../components/pricechart";
import { ethers } from "ethers";
import { gencoin } from "../images";
import InfoCard from "../components/InfoCard";
import { FaFacebookF, FaTwitter, FaTelegramPlane, FaCoins } from "react-icons/fa"; // Importing Icons
import { NavLink } from "react-router-dom";


interface CoinInfo {
  address: string;
  chainId: number;
  priceUsd: number;
  priceUsd24hAgo: number;
  marketCapUsd: number;
  totalReserveUsd: number;
}

const Dashboard: React.FC = () => {
  const EXPLOITATION_FEES = 300;
  const CHAINLINK_PRICE_FEED_ADDRESS = '0x86896fEB19D8A607c3b11f2aF50A0f239Bd71CD0';

  const aggregatorV3InterfaceABI = [
    {
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "description",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint80", name: "_roundId", type: "uint80" }],
      name: "getRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "latestRoundData",
      outputs: [
        { internalType: "uint80", name: "roundId", type: "uint80" },
        { internalType: "int256", name: "answer", type: "int256" },
        { internalType: "uint256", name: "startedAt", type: "uint256" },
        { internalType: "uint256", name: "updatedAt", type: "uint256" },
        { internalType: "uint80", name: "answeredInRound", type: "uint80" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "version",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
  ];

  const [coinData, setCoinData] = useState<CoinInfo[]>([]);
  const [goldPrice, setGoldPrice] = useState<number | null>(null);
  const [genPrice, setGenPrice] = useState<number | null>(null);
  const [chartPriceHistory, setChartPriceHistory] = useState<{ date: string; price: number }[]>([]);

  useEffect(() => {
    async function fetchCoinData() {
      const url = "https://api.coinbrain.com/public/coin-info";
      const payload = {
        "56": ["0x0042e6b7db042cf384c60be5fa347de8a10dd9b3"], // Replace with actual token addresses
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const data = await response.json();
          setCoinData(data);
        } else {
          console.error("Failed to fetch coin data.");
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }

    fetchCoinData();
  }, []);

  useEffect(() => {
    async function fetchGoldPrice() {
      try {
        const provider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed.binance.org/");
        const priceFeed = new ethers.Contract(CHAINLINK_PRICE_FEED_ADDRESS, aggregatorV3InterfaceABI, provider);
        const latestRoundData = await priceFeed.latestRoundData();
        const decimals = await priceFeed.decimals();
        const price = Number(latestRoundData.answer) / 10 ** decimals;
        setGoldPrice(price);
      } catch (error) {
        console.error('Error fetching gold price:', error);
      }
    }
    fetchGoldPrice();
  }, []);
  
  useEffect(() => {
    if (goldPrice !== null) {
      const genPriceCalculated = goldPrice - EXPLOITATION_FEES;
      setGenPrice(genPriceCalculated);
    }
  }, [goldPrice]);
  
  useEffect(() => {
    if (coinData.length > 0) {
      const currentPrice = coinData[0].priceUsd;
      const newChartData = [
        ...chartPriceHistory,
        { date: new Date().toLocaleDateString(), price: currentPrice },
      ];
      setChartPriceHistory(newChartData);
    }
  }, [coinData]);

  const formatMoney = (value: number) => {
    return value?.toLocaleString("en-US", { style: "currency", currency: "USD" }) || "N/A";
  };

  const priceChangePercentage = coinData.length > 0 && coinData[0].priceUsd24hAgo
    ? ((coinData[0].priceUsd - coinData[0].priceUsd24hAgo) / coinData[0].priceUsd24hAgo * 100).toFixed(2)
    : "0.00";

  const PriceComparison = () => {
    const genPriceFormatted = genPrice ? formatMoney(genPrice) : "$0.00 USD";
    const currentPrice = coinData.length > 0 ? coinData[0].priceUsd : 0;
    const currentPriceFormatted = currentPrice ? formatMoney(currentPrice) : "$0.00 USD";

    return (
      <div>
        <PriceStyled><b>{genPriceFormatted}</b></PriceStyled>
        {/* <PriceStyled><b>Initial Price:</b> {currentPriceFormatted}</PriceStyled> */}
        <PriceChange className={genPrice !== null && currentPrice > genPrice ? "positive" : "negative"}>
          {genPrice !== null && currentPrice > genPrice
            ? `Initial price is higher than GEN price by ${formatMoney(currentPrice - genPrice)}`
            : genPrice !== null
            ? `Initial price is lower than GEN price by ${formatMoney(genPrice - currentPrice)}`
            : "GEN price data is not available"}
        </PriceChange>
      </div>
    );
  };

  return (
    <DashboardSection>
      <Navbar />
      <ContentGrid>
        <Card>
          <CardHeader>
            <LogoContainer>
              <img src={gencoin} alt="Gold Era NFT Logo" />
              <div>
                <Title>Gold Era NFT (GEN)</Title>
               
                {/* <SocialLinks>
                  <SocialIcon href="https://facebook.com" target="_blank" aria-label="Facebook">
                    <FaFacebookF />
                  </SocialIcon>
                  <SocialIcon href="https://twitter.com" target="_blank" aria-label="X">
                    <FaTwitter />
                  </SocialIcon>
                  <SocialIcon href="https://t.me" target="_blank" aria-label="Telegram">
                    <FaTelegramPlane />
                  </SocialIcon>
                  <SocialIcon href="https://coinmarketcap.com" target="_blank" aria-label="CoinMarketCap">
                    <FaCoins />
                  </SocialIcon>
                </SocialLinks> */}

                <AssetTags>
                  <Tag>Commodity</Tag>
                  <Tag>Gold</Tag>
                  <Tag>RWA</Tag>
                </AssetTags>
              </div>
            </LogoContainer>
            <PriceContainer>
              <PriceComparison />
              <PriceChange className={parseFloat(priceChangePercentage) >= 0 ? "positive" : "negative"}>
                {parseFloat(priceChangePercentage) >= 0 ? "+" : ""}{priceChangePercentage}%
              </PriceChange>
            </PriceContainer>
          </CardHeader>

          <SectionTitle>Token Overview</SectionTitle>
          <ContractAddress><strong>Token Contract:</strong> <a href="https://bscscan.com/token/0x0042e6b7db042cF384C60be5FA347DE8a10dD9B3" target="_blank">{coinData.length > 0 ? coinData[0].address : "N/A"}</a> </ContractAddress>
          <ContractAddress><strong>NFT Contract:</strong> <a href="https://bscscan.com/token/0xBAD7993E6EF6a126137a8aAaBcFCcf8DB904e5bE" target="_blank">0xBAD7993E6EF6a126137a8aAaBcFCcf8DB904e5bE</a> </ContractAddress>
          <Description>
          The Gold Era NFT (GEN) is a real-world asset-backed token, with each GEN token representing one troy ounce of in-ground gold. Contributors are being offered a token fully supported by real gold in-ground, tangible assets situated in Guinea, West Africa <NavLink to="/documents" className="font-bold">(See authentication documents)</NavLink>. Gold Era NFT a crypto token that is paired with and backed by mineral resources located within the concessions of Atlantic Gold Mine SARL in northeast Guinea, a region renowned for its abundant deposits of gold and other minerals.          </Description>
          
          <PriceChartContainer>
            <PriceChart priceHistory={chartPriceHistory} />
          </PriceChartContainer>

          <InfoGrid>
            <InfoCard title="Token Name" value="Gold Era NFT" />
            <InfoCard title="Symbol" value="GEN" />
            <InfoCard title="1 GEN" value="1 Troy Oz" />
            <InfoCard title="1 GEN Price" value={genPrice ? formatMoney(genPrice) : "$0.00 USD"} />
            <InfoCard title="Initial Price" value={coinData.length > 0 ? formatMoney(coinData[0].priceUsd) : "N/A"} />
            <InfoCard title="Chain ID" value={coinData.length > 0 ? coinData[0].chainId.toString() : "N/A"} />
            <InfoCard title="Price 24h Ago" value={coinData.length > 0 ? formatMoney(coinData[0].priceUsd24hAgo) : "N/A"} />
            <InfoCard title="Market Cap" value={coinData.length > 0 ? formatMoney(coinData[0].marketCapUsd) : "$0.00 USD"} />
            <InfoCard title="Total Reserve" value={coinData.length > 0 ? formatMoney(coinData[0].totalReserveUsd) : "N/A"} />
          </InfoGrid>
        </Card>
      </ContentGrid>
    </DashboardSection>
  );
};

const DashboardSection = styled(Container)`
  margin-left: 12vw;
    padding: 2rem;
    color: #1f2937;
    margin-right: -1vw;

  @media (max-width: 768px) {
       margin-left: 0;
        padding: 0rem;
        margin-top: 27px;
  }
`;

const ContentGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

const Card = styled.div`
  // background: #ffffff;
  border-radius: 8px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 8rem 5rem;
    margin-top: -58px;


  @media (max-width: 768px) {
 margin-left: 0;
        padding: 1rem;
        margin-top: -2px;  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 1rem;

    @media (max-width: 768px) {
      margin-right: 0.5rem;
    }
  }
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const AssetTags = styled.div`
  margin-top: 0.3rem;
  display: flex;
  gap: 0.5rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const Tag = styled.span`
      background: #bdc3c7;
    color: #1f2937;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;

  @media (max-width: 768px) {
    font-size: 0.75rem;
  }
`;

const PriceContainer = styled.div`
  text-align: right;

  @media (max-width: 768px) {
    text-align: left;
    margin-top: 0.5rem;

  }
`;

const Price = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #1f2937;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const PriceChange = styled.p`
  font-size: 1rem;

  &.positive {
    color: #3bca00;
  }

  &.negative {
    color: #000;
  }

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  color: #1f2937;
  margin-top: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-top: 1rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  color: #4b5563;
  line-height: 1.5;
  margin-top: 1rem;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;


const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 768px) {
    margin-top: 0.5rem;
  }
`;

const SocialIcon = styled.a`
  color: #1f2937;
  font-size: 1.25rem;
  transition: color 0.3s;

  &:hover {
    color: #10b981;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;


const PriceStyled = styled.p`
  font-size: 2rem; // Increase the font size
  font-weight: bold;
  margin: 0;
  color: #1f2937;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;



const ContractAddress = styled.p`
  font-size: 1rem;
  color: #4b5563;
  margin-top: 1rem;

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
`;

const PriceChartContainer = styled.div`
  height: 202px; /* Set a fixed height or adjust as needed */
  margin-top: 2rem;

  @media (max-width: 768px) {
    height: 200px; /* Adjust for smaller screens */
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
`;
export default Dashboard;
